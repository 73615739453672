import axios from "axios";
import config from "@/config.js";
import App from "@/main";
import store from "@/store";

class ApiIntegrface {
  async request(
    method,
    url,
    params,
    payload,
    callback,
    errhandler,
    successMessage,
    errMessage,
    loading
  ) {
    const endpoint = config.backendEndpoint;
    // if(loading){
    //   App.$vloading.show()
    // }
    await axios({
      method: method,
      url: endpoint + url,
      params: params,
      data: payload,
      timeout: 20000,
      headers: { Authorization: "Bearer " + store.state.Token },
      paramsSerializer: function (params) {
        var res = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        return res;
      },
      responseType: "json",
      responseEncoding: "utf8",
      withCredentials: false,
    })
      .then(function (response) {
        if (successMessage) {
          App.notify.show({ message: successMessage });
        }
        if (callback) callback(response.data, payload, url);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 502) {
            App.notify.show({
              message:
                "Unable to connect to the server! Please check you internet connection ",
              type: "error",
            });
            console.log(error.response);
          } else {
            if (!errMessage && !errhandler) {
              if (error.response.status === 400) {
                App.notify.show({
                  message:
                    "Invalid data entered! Please fill the fields with the appropriate data",
                  type: "error",
                });
              } else {
                App.notify.show({
                  message:
                    "An error occured! Sorry for having troubled you :(. Please contact support ",
                  type: "error",
                });
              }
            } else {
              if (errMessage) {
                App.notify.show({ message: errMessage, type: "error" });
              }
              if (errhandler) {
                errhandler(error.response);
              } else {
                console.log(error.response.data);
              }
            }
          }
        } else if (error.request) {
          App.notify.show({
            message: "The server did not respond! Please contact support",
            type: "error",
          });
          console.log(error.message);
        } else {
          App.notify.show({
            message:
              "Internal server error! Sorry for havivng troubled you :(. Please contact support",
            type: "error",
          });
          console.log("ERROR", error.message);
          console.log(error.config);
          console.log(error.request);
          console.log(error.response);
        }
      })
      .then(function () {
        if (loading) {
          App.$vloading.hide();
        }
      });
  }
  get(
    url,
    params,
    callback,
    errhandler,
    successMessage = null,
    errMessage = null,
    loading = true
  ) {
    this.request(
      "get",
      url,
      params,
      null,
      callback,
      errhandler,
      successMessage,
      errMessage,
      loading
    );
  }
  post(
    url,
    payload,
    callback,
    errhandler,
    successMessage = null,
    errMessage = null,
    loading = true
  ) {
    this.request(
      "post",
      url,
      null,
      payload,
      callback,
      errhandler,
      successMessage,
      errMessage,
      loading
    );
  }
  put(
    url,
    payload,
    callback,
    errhandler,
    successMessage = null,
    errMessage = null,
    loading = true
  ) {
    this.request(
      "put",
      url,
      null,
      payload,
      callback,
      errhandler,
      successMessage,
      errMessage,
      loading
    );
  }
  delete(
    url,
    payload,
    callback,
    errhandler,
    successMessage = null,
    errMessage = null,
    loading = true
  ) {
    this.request(
      "delete",
      url,
      null,
      payload,
      callback,
      errhandler,
      successMessage,
      errMessage,
      loading
    );
  }
}

export default ApiIntegrface;
